<template>
  <div style="padding: 0 3%">
    <el-form :model="tempForm" :inline="true" ref="tempForm" label-width="80px" style="margin-top: 5%;">
      <el-form-item label="模板类型">
		  <p>至2022年1月19日起已更改为标准账单格式模板，</p>
		  <p>此模板为结账、申请贷款、金融服务统一标准模板。</p>
        <!-- <el-radio-group v-model="tempForm.reckonType">
          <el-radio :label="1" border>点位结算模板</el-radio>
          <el-radio :label="2" border>件数结算模板</el-radio>
          <el-radio :label="3" border>吨位结算模板</el-radio>
          <el-radio :label="4" border>公里结算模板</el-radio>
        </el-radio-group> -->
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="margin-top: 10%;margin-left: 25%;">
      <!--		  <el-button @click="cancel">取 消</el-button>-->
      <el-button type="primary" @click="downTemplate">下 载</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'templateDown',
  data() {
    return {
      tempDialog: true,
      tempForm: {
      }
    }
  },
  created() {
  },
  methods: {
    downTemplate: function () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.HTTP.download('/file/downloadTemplate').then(res => {
        const content = res.data;
        const blob = new Blob([content]);
        let type = this.tempForm.reckonType;
        let fileName = '标准账单结算模板.xls';
        if ("download" in document.createElement("a")) {
          // 支持a标签download的浏览器
          const link = document.createElement("a"); // 创建a标签
          link.download = fileName; // a标签添加属性
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click(); // 执行下载
          URL.revokeObjectURL(link.href); // 释放url
          document.body.removeChild(link); // 释放标签
        } else {
          // 其他浏览器
          navigator.msSaveBlob(blob, fileName);
        }
        loading.close();
      }).catch(err => {
        loading.close();
        this.$message.error(err);
      })
    },
    // cancel:function(){
    // 	this.$emit('cancel',0);
    // }
  }
};
</script>

<style scoped>
.grey-line .el-row {
  border-bottom: 1px solid #dcdfe6;
}

.grey-line .el-row:last-child {
  border-bottom: none;
}

.userInfo_list .grid-content {
  line-height: 20px;
}

.userInfo_list .lost-title {
  font-weight: 400;
  color: #1f2f3d;
  font-size: 20px;
}

.userInfo_list .title-content {
  font-weight: 400;
  color: #1f2f3d;
  font-size: 20px;
  margin-left: 5px;
}

.grid-content {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center !important;
}

.complaint-title {
  align-items: center;
  margin-right: 5px;
  font-size: 16px;
  display: flex;
  font-weight: bolder;
  color: #1f2f3d;
  white-space: nowrap;
}

.title-content {
  display: flex;
  align-items: center;
}
</style>
